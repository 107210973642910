.full {
  width: 100vw;
  height: 100vh;
}

.middle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}